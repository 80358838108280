@font-face {
  font-family: 'FuturistFixedWidth';
  src: local('FuturistFixedWidth'), url('./fonts/FuturistFixedWidthRegular.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .snapi-title-lg {
    @apply font-futurist text-2xl;
  }

  .snapi-title {
    @apply font-futurist text-base;
  }

  .snapi-body {
    @apply font-epilogue font-light text-base leading-relaxed;
  }

  .snapi-body-xs {
    @apply font-epilogue font-light text-xs leading-relaxed;
  }

  .snapi-input {
    @apply focus:outline-none font-epilogue font-light text-base border border-gray-500 px-4 rounded-3xl h-10;
  }

  .snapi-input-error {
    @apply font-epilogue font-light text-xs leading-relaxed text-red-500;
  }

  .snapi-button {
    @apply flex justify-center items-center font-futurist text-base text-white text-center bg-black h-10 px-6 rounded-full uppercase outline-none focus:outline-none;
    min-width: 256px;
  }

  .snapi-button-normal {
    @apply flex justify-center items-center font-futurist text-base text-black text-center bg-white h-10 rounded-full uppercase outline-none focus:outline-none;
  }

  .snapi-checkbox {
    @apply flex justify-center items-center font-epilogue text-base text-black text-center cursor-pointer bg-white h-10 rounded-full outline-none focus:outline-none border border-gray-500;
  }

  .snapi-link, .snapi-link:visited {
    @apply font-epilogue text-base font-normal;
    color: rgb(27, 145, 170);
  }

  .snapi-link:hover {
    color: #0056b3;
  }

  input[type="radio"]:checked + .snapi-checkbox {
    @apply text-white text-center bg-black;
  }
}

.snapi-button:disabled {
  background-color: #a6a6a6;
  cursor: default;
}

@media (min-width: 1024px) {
  .snapi-input-lg-33-minus-gap {
    width: calc(33% - 0.75rem / 2);
  }
}

.PhoneInput--focus .PhoneInputInput {
  outline: none;
}

.PhoneInputInput {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100%;
}
